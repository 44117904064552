import React from "react";
import Layout from "../components/layout/layout";

const NftCollection = () => {
    return (
        <Layout title="NFT Collection">
            <h1>My NFT Collection</h1>
        </Layout>
    );
};

export default NftCollection;
